<template>
  <form name="form" @submit.prevent="handleLogin">
    <b-card
      title="App Login"
      style="max-width: 30rem; margin: auto"
      class="mb-2"
    >
      <b-card-text>
        <b-container fluid>
          <b-row>
            <b-col sm="3">
              <label for="username">Benutzer</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                id="username"
                required
                v-model="user.username"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="3">
              <label for="password">Password</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                id="password"
                required
                type="password"
                v-model="user.password"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <b-button block type="submit">Login</b-button>
              <div class="alert alert-danger" role="alert" v-if="message">
                {{ message }}
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-card-text>
    </b-card>
  </form>
</template>

<script>
/* eslint-disable */
import User from "../../../models/basedata/User";

export default {
  name: "login",
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  data() {
    return {
      user: new User(),
      loading: false,
      message: "",
    };
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/home");
    } else {
      localStorage.removeItem("sportevent");
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;

      /*
      if (this.errors.any()) {
        this.loading = false;
        return;
      }*/

      if (this.user.username && this.user.password) {
        this.$store.dispatch("auth/login", this.user).then(
          () => {
            this.$router.push("/home");
          },
          // eslint-disable-next-line
          (error) => {
            this.loading = false;
            this.message = error.message;
          }
        );
      }
    },
  },
};
</script>

<style scoped></style>
